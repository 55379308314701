 :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-inline {
    height: 100vh;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-dark .ant-menu-item-selected {
    background-color: #90cdf4;
    font-weight: 600;
    color: white;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light .ant-menu-item-selected {
    font-weight: 500;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #324453;
}


:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-inline .ant-menu-item {   
    margin-bottom: 1rem;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-root.ant-menu-inline{
    width: 13rem;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
    height: 100vh;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-dark.ant-menu-root.ant-menu-inline{
    width: 13rem;
}

.chakra-ui-dark .css-1ywcg5p:not([data-theme]), [data-theme=dark] .css-1ywcg5p:not([data-theme]), .css-1ywcg5p[data-theme=dark] {
    background-color: #011529 
}


